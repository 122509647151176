<template>
  <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <main class="">
      <div class="main_content align-items-center">
        <div class="row justify-content-between">
          <div class="col-md-12 col-lg-4">
            <div class="heading">
              <h2>All Clients</h2>
              <h3>PBE Management Dashboard</h3>
            </div>
          </div>
          <div class="col-md-12 col-lg-8 text-lg-end mt-2 mt-lg-0" v-show="addPermission">
            <router-link :to="{ name: 'add_client' }" class="btn btn_primary">Add New Client
            </router-link>
            <!-- <a class="btn btn_primary ml_10"  href="#">Export Clients</a> -->
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            
              <div class="table_card">
                <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
                  <div class="dataTables_length" id="datatable_length">
                    <label>Show
                      <select name="datatable_length" aria-controls="datatable" class="" v-model.number="itemsPerPage"
                        @change="getProductsBysearch">
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>100</option>
                        <option>500</option>
                      </select>
                      entries</label>
                  </div>
                  <div id="datatable_filter" class="dataTables_filter">
                    <label><input type="search" class="" placeholder="Search" aria-controls="datatable"
                        v-model="listConfig.search_string" v-on:keyup.enter="getProductsBysearch" />
                      <a v-if="listConfig.search_string" href="javascript:void(0)" @click="getProductsBysearch" class="search_icon cntr_search">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search"
                          viewBox="0 0 16 16">
                          <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                          </svg>
                      </a>
                      <a href="javascript:void(0)" v-if="listConfig.search_string"
                        @click="listConfig.search_string = null; getProductsBysearch()" class="crossicon cntr_cross">
                        X
                      </a>
                    </label>
                  </div>
                </div>
                <div class="table-responsive table_audit_log">
                  <table class="table table-bordered table dataTable no-footer">
                    <thead>
                      <tr>
                        <th class="sort">
                          <SortIcons :lable="'Client Name'" :listConfig="listConfig" :sortBy="'company_name'"
                            @sorting="sorting($event)" />
                        </th>
                        <th class="sort">
                          <SortIcons :lable="'Client Code'" :listConfig="listConfig" :sortBy="'company_code'"
                            @sorting="sorting($event)" />
                        </th>
                        <th class="sort">
                          <SortIcons :lable="'Status'" :listConfig="listConfig" :sortBy="'status'"
                            @sorting="sorting($event)" />
                        </th>
                        <th class="sort date_of_created">
                          <SortIcons :lable="'Date Created '" :listConfig="listConfig" :sortBy="'created_at'"
                            @sorting="sorting($event)" />
                        </th>
                        <th scope="col" v-show="deletepermission || addPermission">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(client, indx) in clients" :key="indx">
                        <td>{{ client.company_name }}</td>
                        <td>{{ client.company_code }}</td>
                        <td>{{ (client.status) ? 'Active' : 'InActive' }}</td>
                        <td>
                          <p class="audit_date_date">
                            {{ client.created_at | formatDateAmerican }}
                          </p>
                        </td>
                        <td>
                          <router-link :to="{
                              name: 'edit_client',
                              params: { id: client.u_id },
                            }" class="" v-show="addPermission" title="Edit">
                            <img
                                  class="edit-icon"
                                  src="../../assets/admin/images/Edit_Icon.svg"
                                  alt="img"
                                />
                          </router-link>

                          <!-- <button class="btn btn_primary ml_10" v-show="deletepermission" @click="openModel(client.u_id)" data-bs-toggle="modal" data-bs-target="#userClientModal"> -->

                            <a href="javascript:void(0)"  v-show="deletepermission" @click="openModel(client.u_id)" data-bs-toggle="modal" data-bs-target="#userClientModal" title="Delete">
                            <svg 
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                style="margin-left: 10px; margin-top: -3px"
                                viewBox="0 0 13.503 14.94"
                              >
                                <path
                                  data-name="Path 108"
                                  d="M14.784 5.876 14.16 14.6a1.438 1.438 0 0 1-1.434 1.336h-5.95A1.438 1.438 0 0 1 5.342 14.6l-.623-8.724m3.594 2.875v4.313m2.876-4.313v4.313m.719-7.189V3.719A.719.719 0 0 0 11.189 3H8.313a.719.719 0 0 0-.719.719v2.157M4 5.876h11.5"
                                  transform="translate(-3 -2)"
                                  style="
                                    fill: none;
                                    stroke: #999292;
                                    stroke-linecap: round;
                                    stroke-linejoin: round;
                                    stroke-width: 2px;
                                  "
                                ></path>
                              </svg>
                          </a>
                        </td>
                      </tr>
                      <tr v-if="data_length==0">
                        <td colspan="7" class="text-center">No Records found</td>
                      </tr>
                    </tbody>
                    <tfoot></tfoot>
                  </table>
                </div>
                <p class="float-lg-start text-sm-center">{{ positionText }}</p>
                <VPagination class="dataTables_paginate" :totalPages="pages.length" :perPage="itemsPerPage"
                  :currentPage.sync="currentPage" @pagechanged="onPageChange" />
              </div>
     
          </div>
        </div>
      </div>
    </main>


    <div class="modal fade meet_our_team_modal user_box_up" id="userClientModal" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header border-bottom-0 pb_0">
            <button type="button" class="btn-close sml_cross_btn_admin" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body pt_0 w-100">
            <div class="mb-3 form-group text-center sml_del_font">
              By deleting this client, all associated data will be permanently removed. This action cannot be undone. <strong>Are you sure you want to proceed?</strong>
            </div>
            <div class="mb-3 form-group maxheight">
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">
                  Reason <span>*</span></label>
                <textarea class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  placeholder="Reason of delete" name="delete_reason" v-model="delete_reason"
                  v-validate="'required|email'" v-on:keyup="countdown" maxlength="150" />
                <label class="text-end w-100">{{remainingCount}} characters remaining</label>
                  <small
                    class="error text-danger"                    
                  >{{delete_res}}                    
                  </small>
                </div>

            </div>
            <div class="text-center mt_30">
              <button
                class="btn btn_primary mr_10"
                type="button"
                @click="deleteclient()"
              >
                Yes
              </button>
              <button
                class="btn btn_outline"
                type="button"
                @click="closeModel()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import $ from "jquery";
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
import commonFunction  from '../../mixin/commonFunction'

export default {
  name: "All_Clients",
  mixins: [commonFunction],
  components: {
    VPagination,
    SortIcons,
  },
  data() {
    return {
      clients: [],
       currentPage: 1,
       itemsPerPage: 10,
       listConfig: {
          offset: (this.currentPage - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
          sortBy: "company_name",
          order: "ASC",
          search_string: null,
       },
      data_length: 0,

      addPermission: false,
      deletepermission: false,
      delete_clientid: "",
      delete_res:"",
      delete_reason:"",
      maxCount: 150,
      remainingCount: 150,
      hasError: false
    };
  },
  computed: {
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getClients();
    },
  },
  mounted() {
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
    var _this = this;
    _this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "company_name",
      order: "ASC",
      search_string: null,
    };
    _this.getClients();
    if(this.$route.params.successMsg){ 
      this.$toast.success(this.$route.params.successMsg, {
        position: "top-right",
        duration: 5000,
      });
    }
    if(this.$route.params.errorMsg){ 
      this.$toast.error(this.$route.params.errorMsg, {
        position: "top-right",
        duration: 5000,
      });
    }
  },
  beforeMount() {
    // this.$parent.$refs.fullpageloader.is_full_page_loader = true;
    if (localStorage.getItem("userToken")) {
      window.addEventListener("scroll", this.scrollvue);
      // Store for check permissiondeletepermission
      let res = this.$store.dispatch("auth/getAuthUser");
      res
        .then((value) => {
          let allPermission = value.user.roles.all_permission;
          if (allPermission.includes("clients-edit")) {
            this.addPermission = true;
          }
          this.deletepermission = allPermission.includes("clients-delete");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.tokenExpired();
    }
  },
  methods: {
     onPageChange(page) {      
      this.currentPage = page;
    },
    sorting($event) {
      console.log($event);
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.getClients();
    },
    getProductsBysearch() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.getClients();
    },
    getClients() {
      if (localStorage.getItem("userToken")) {
        this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        var _this = this;
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/client-pagination",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: {request_data : this.encodeAPIRequest(_this.listConfig)},
        };
        _this
          .axios(config)
          .then(({ data }) => {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.clients = decodedJson.allClients;
            _this.data_length = decodedJson.counts;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          })
          .catch(({ response }) => {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            if (response.status == 401) {
              _this.$router.push({ name: "login" });
            }
          });
      } else {
        this.tokenExpired();
      }
    },
    dataTableWithData() {
      $("#datatable").DataTable({
        aoColumnDefs: [
          {
            bSortable: false,
            aTargets: [-1],
          },
        ],
      });
    },
    deleteclient() {
      var _this = this;
      let client_id = this.delete_clientid;

      var delete_reason = _this.delete_reason;
      
      if(!delete_reason){
        _this.delete_res = "Reason is required.";
        return false;
      }else{
        _this.delete_res = '';
      }
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      var requestData = {'reason': delete_reason,'id':client_id};
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/remove-client/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: this.encodeAPIRequestWithImages(requestData),
      };
      _this
        .axios(config)
        .then(({ data }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          _this.closeModel();
          _this.$toast.success(data.message, {
            position: "top-right",
            duration: 5000,
          });
          _this.getClients();
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          _this.closeModel();
          _this.$toast.error(response.data.error, {
            position: "top-right",
            duration: 5000,
          });
           _this.getClients();
        });
    },
    openModel(delete_client) {
      this.delete_reason = '';
      this.delete_res = '';
      this.delete_clientid = delete_client;
    },
    closeModel() {
      document.getElementsByClassName("btn-close")[0].click();
    },
    countdown: function() {
      this.remainingCount = this.maxCount - this.delete_reason.length;
      this.hasError = this.remainingCount < 0;
      if(this.hasError){
        console.log('Haserror:',this.hasError);
        return;
      }
    },
  },
};
</script>